
new WOW({ offset: 70 }).init();

$(document).ready(function () {

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	function fixSizes() {
		$('.RotatorTestimonialContent').sameHeight();
	}

	setTimeout(fixSizes, 300);

	$(window).on("resize", function () {
		setTimeout(fixSizes, 300);
	});

	$(".testimonials .carousel").attr("id", "testimonialCarousel");

	$(".home-gallery__previous").click(function (e) {
		var img = $(".home-gallery__slide").first();
		img.animate({ width: 0 }, 1000, "linear", function () {
			img.remove();
			$(".home-gallery__inner").append(img);
			img.width(98);
		});
		e.preventDefault();
		return false;
	});

	$(".home-gallery__next").click(function (e) {
		var img = $(".home-gallery__slide").last();
		img.width(0);
		img.remove();
		$(".home-gallery__inner").prepend(img);
		img.animate({ width: 98 }, 1000, "linear");
		e.preventDefault();
		return false;
	});
});

